<template>
  <div class="navigation-form-container">
    <b-row>
      <b-col sm="6" md="2">
        <b-form-group id="input-group-is_show" label="ئوچۇقمۇ ؟" label-for="is_show">
          <b-form-radio-group
            id="is_show"
            v-model="formData.is_show"
            :options="yesOrNot"
            buttons />
        </b-form-group>
      </b-col>
      <b-col sm="6" md="4" v-if="formData.view_type === 'banner'">
        <b-form-group id="input-belongs" label="ئېلان تەۋە بەتلەر" label-for="belongs">
          <b-form-checkbox-group size="lg" id="belongs" v-model="formData.belongs" :options="belongs" name="belongs" ></b-form-checkbox-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="6" md="3" >
        <b-form-group id="input-group-title" :label="`${titleForViewType} ماۋزوسى `" label-for="title" >
          <b-form-input id="title" v-model="formData.title" :state="validateState('title')" type="text" aria-describedby="title-live-feedback" :placeholder="`${titleForViewType} ماۋزوسىنى كىرگۈزۈڭ `" />
          <b-form-invalid-feedback id="title-live-feedback">
            {{ titleForViewType }} ماۋزوسىنى كىرگۈزۈڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col v-if="viewType === 'navigation'" sm="6" md="3">
        <b-form-group id="input-group-background" label="ماۋزو رەڭ نۇمۇرى" label-for="title-color">
          <b-input-group>
            <b-form-input id="background" placeholder=" ماۋزو رەڭگىنى تاللاڭ، قۇرۇق قويسىڭىزمۇ بولىدۇ" style="direction: ltr" v-model="formData.color" />
            <b-input-group-append>
              <b-button class="d-flex align-items-center justify-content-center">
                <colorPicker ref="colorPicker" v-model="formData.color || ''" @change="changeTitleColor" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col sm="6" md="3">
        <b-form-group id="input-group-9" label=" تەرتىبى" label-for="sort">
          <b-form-input style="width: 100%;" id="sort" min="0" v-model="formData.sort" type="number" placeholder=" تەرتىبىنى كىرگۈزۈڭ، قۇرۇق قويسىڭىزمۇ بولىدۇ" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="6" md="2" v-if="viewType === 'banner'">
        <b-form-group id="input-view_type" :label="`${titleForViewType} شەكلى `" label-for="view_type">
          <b-form-radio-group id="view_type" v-model="formData.view_type" :options="viewTypes" buttons name="radios-btn-default" />
        </b-form-group>
      </b-col>
      <b-col sm="6" md="6">
        <b-form-group :state="validateState('target')" aria-describedby="target-live-feedback" id="input-group-2" :label="`${titleForViewType} تۈرى `" :description="optionDesc" label-for="target">
          <b-form-radio-group id="target" v-model="formData.target" @change="changeTarget" :options="targetOptions" buttons name="radios-btn-default" />
          <b-form-invalid-feedback id="target-live-feedback">
            {{ titleForViewType }} تۈرىنى تاللاڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row >
      <template v-if="formData.target && formData.target !== 'contract'">
      <b-col sm="6" md="3">
        <b-form-group :label="targetLabel" >
          <b-input-group>
            <b-form-input :state="validateState('to')" aria-describedby="target-live-feedback" v-model="shouldChoose ? targetTitle : formData.to" :style="{direction: shouldChoose ? 'rtl' : 'ltr'}" :placeholder="feedBackLabel" :readonly="shouldChoose " />
            <b-input-group-append v-if="shouldChoose">
              <b-button class="d-flex justify-content-center align-content-center" v-if="formData.target === 'film'" @click="onClickOpenSearchFilmModal"> تاللاڭ </b-button>
              <b-button class="d-flex justify-content-center align-content-center" v-if="formData.target === 'film_category'" @click="onClickOpenSearchCategoryModal"> تاللاڭ </b-button>
              <b-button class="d-flex justify-content-center align-content-center" v-if="formData.target === 'film_flag'" @click="onClickOpenSearchFlagModal"> تاللاڭ </b-button>
              <b-button class="d-flex justify-content-center align-content-center" v-if="formData.target === 'page'" v-b-modal.pagesList> تاللاڭ </b-button>
              <b-button class="d-flex justify-content-center align-content-center" v-if="formData.target === 'film_collection'" @click="onClickOpenSearchCollectionModal"> تاللاڭ </b-button>
            </b-input-group-append>
            <b-form-invalid-feedback id="target-live-feedback">{{ feedBackLabel }}</b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </b-col>
      </template>
      <b-col v-if="formData.target === 'mp'" sm="6" md="2">
        <b-form-group id="input-group-target-option" label="appid">
          <b-form-input style="direction: ltr" id="appid" v-model="formData.target_option" :state="validateState('target_option')" type="text" aria-describedby="target_option-live-feedback" placeholder="ئەپچاق AppId سىنى كىرگۈزۈڭ" />
          <b-form-invalid-feedback id="target_option-live-feedback">
            ئەپچاق AppId سىنى كىرگۈزۈڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col v-if="formData.belongs.includes('show')" sm="6" md="3">
        <b-form-group id="input-group-belongs_option" label="ئېلان تەۋە سەھىپە">
          <b-input-group>
          <b-form-input id="belongs_option" readonly v-model="belongsTitle" :state="validateState('belongs_option')" type="text" aria-describedby="belongs_option-live-feedback" placeholder="تەپسىلات بەتتىكى ئېلان ئۈچۈن، سەھىپە تاللاڭ" />
          <b-input-group-append>
            <b-button class="d-flex justify-content-center align-content-center" @click="onClickOpenSearchShowFlagModal"> تاللاڭ </b-button>
          </b-input-group-append>
          <b-form-invalid-feedback id="belongs_option-live-feedback">
            تەپسىلات بەتتىكى ئېلان ئۈچۈن سەھىپە تاللاڭ
          </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" md="3">
        <b-form-group :state="validateState('navigation_image')" aria-describedby="target-live-feedback" id="input-group-navigation_image" :description="navigationImageDesc" :label="uploaderLabel" label-for="navigation_image">
          <image-uploader v-model="formData.navigation_image" :upload-form="{strategy: viewType}" :accepted-file-types="acceptedFileTypes" />
          <b-form-invalid-feedback id="target-live-feedback">
            {{ uploaderLabel }} تاللاڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <search-film-modal v-model="filmModalState" @changed="onChangeFilm" :selected="formData.target === 'film' ? formData.to : null" />
    <search-film-collection-modal v-model="collectionModalState" @changed="onChangeFilmCollection" :selected="formData.target === 'film_collection' ? formData.to : null" />
    <search-category-modal v-model="categoryModalState" @changed="onChangeFilmCategory" :selected="formData.target === 'film_category' ? formData.to : null" />
    <search-flag-modal v-model="flagModalState" @changed="onChangeFilmFlag" :selected="formData.target === 'film_flag' ? formData.to : null" />
    <search-flag-modal v-model="showFlagModalState" @changed="onChangeShowFlag" :selected="formData.belongs_option" />
    <b-modal size="lg" hide-footer header-close-content="" body-class="bg-white" :centered="true" id="pagesList" title="ئىچكى بەتلەرنى تاللاڭ">
      <b-row>
        <b-col sm="6" md="6" v-for="page in pages" :key="page.value">
          <label class="kt-option" @click="onChangePage(page)">
            <span class="kt-option__control">
              <b-form-radio class="kt-radio kt-radio--bold kt-radio--brand" v-model="formData.to" name="to" :value="page.value" />
              <!--<span class="kt-radio kt-radio&#45;&#45;bold kt-radio&#45;&#45;brand" >
                <input type="radio" name="m_option_1" value="1">
              </span>-->
            </span>
            <span class="kt-option__label">
              <span class="kt-option__head">
                <span class="kt-option__title"> {{ page.title }} </span>
              </span>
              <span class="kt-option__body">{{ page.description }}</span>
            </span>
          </label>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
  import ImageUploader from "@v@/partials/layout/ImageUploader";
  import navigationMixin from "@m@/navigation.mixin";
  import validateStateMixin from "@m@/common/validateState.mixin";
  import { validationMixin } from "vuelidate";
  import { required, requiredIf } from "vuelidate/lib/validators";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  import "@c@/plugins/vue-color-picker";
  import {sprintf} from "@c@/helpers/helpers";
  import searchFilmModal from "@v@/components/modals/searchFilmModal";
  import searchCategoryModal from "@v@/components/modals/searchFilmCategoryModal";
  import searchFlagModal from "@v@/components/modals/searchFilmFlagModal";
  import searchFilmCollectionModal from "@v@/components/modals/searchFilmCollectionModal";
  import { mapGetters } from "vuex";
  export default {
    name: "NavigationForm",
    mixins: [ validationMixin, validateStateMixin, navigationMixin, sweetAlertMixin ],
    components: { ImageUploader, searchFilmModal, searchCategoryModal, searchFlagModal, searchFilmCollectionModal },
    props: {
      action: {
        type: String,
        default: 'create'
      },
      modelId: {
        type: [String, Number],
      },
      viewType: {
        type: String,
        default: ()=> 'banner',
        validator: function (value) {
          // 这个值必须匹配下列字符串中的一个
          return ['banner', 'navigation'].includes(value)
        }
      }
    },
    validations(){
      let validates = {
        title: { required },
        navigation_image: { required },
        target: { required },
        to: { required: requiredIf((formData)=>{
          return formData.target !== 'contract';
        })},
        target_option: { required: requiredIf((formData)=>{
          return formData.target === 'mp';
        })},
        belongs_option: { required: requiredIf((formData)=>{
          return formData.belongs.includes('show');
        })}
      };
      if ( this.viewType === 'banner' ){}else if ( this.viewType === 'navigation' ){
        // validates['color'] = { required };
      }
      return {
        formData: validates
      }
    },
    computed: {
      ...mapGetters(['enableList']),
      toLabel(){
        switch (this.formData.target) {
          case 'film_category':
            return 'تۈرىنى تاللاڭ';
          case 'film_flag':
            return 'سەھىپە تاللاڭ';
          case 'film':
            return 'فىلىم تاللاڭ';
          case 'film_collection':
            return 'توپلام تاللاڭ';
          case 'mp':
            return 'ئەپچاق بەت ئادېرسى';
          case 'external_link':
            return 'سىرتقى ئۇلانما ئادېرسى';
          case 'page':
            return 'ئىچكى بەتنى تاللاڭ';
          case 'contract':
            return 'كېلىشىم بەت';
        }
      },
      titleForViewType(){
        switch (this.viewType) {
          case "banner":
            return "ئېلان"
          case "navigation":
            return "تىزىملىك"
          case "landscape":
            return "لەيلىمە ئېلان"
        }
      },
      optionDesc(){
        let str = "%s رەسىمىنى چەككەندە %s  ئاتلايدۇ";
        switch (this.formData.target) {
          case "film":
            return sprintf(str, this.titleForViewType, "تاللاپ بەرگەن فىلىمگە ")
          case "film_category":
            return sprintf(str, this.titleForViewType, "تاللاپ بەرگەن فىلىم تۈرلىرىگە ")
          case "film_flag":
            return sprintf(str, this.titleForViewType, "تاللاپ بەرگەن سەھىپىگە ")
          case "film_collection":
            return sprintf(str, this.titleForViewType, "تاللاپ بەرگەن توپلامغا ")
          case "page":
            return sprintf(str, this.titleForViewType, "تاللاپ بەرگەن ئىچكى بەتكە ")
          case "contract":
            return sprintf(str, this.titleForViewType, "كېلىشىمگە قوشۇلۇش بېتىگە")
          case "mp":
            return sprintf(str, this.titleForViewType, "ئۇچۇرى تولدۇرۇلغان ئەپچاققا ")
          case "external_link":
            return sprintf(str, this.titleForViewType, "تولدۇرۇپ بەرگەن سىرتقى ئۇلانمىغا ")
        }
      },
      uploaderLabel(){
        switch (this.viewType) {
          case "banner":
          case "landscape":
            return "ئېلان رەسىمى"
          case "navigation":
            return "تىزىملىك سىنبەلگىسى"
        }
      },
      acceptedFileTypes(){
        switch (this.viewType) {
          case "banner":
            return "image/png, image/gif, image/jpg, image/jpeg"
          case "navigation":
            return "image/png, image/gif"
        }
      },
      targetLabel(){
        switch (this.formData.target) {
          case "film":
            return "فىلىم تاللاڭ"
          case "film_category":
            return "فىلىم تۈرىنى تاللاڭ"
          case "film_flag":
            return "فىلىم سەھىپىسىنى تاللاڭ"
          case "film_collection":
            return "توپلام تاللاڭ"
          case "mp":
            return "ئەپچاق بەت ئادېرسى"
          case "external_link":
            return "ئۇلانما ئادېرسىنى تولدۇرۇڭ"
          case "page":
            return "ئىچكى بەتنى تاللاڭ"
          case "contract":
            return "كېلىشىم ئەزالىق بەت"
        }
      },
      feedBackLabel(){
        let str = "سول تەرەپتىكى كونۇپكىنى بېسىپ %s تاللاڭ";
        let str_1 = "%s كىرگۈزۈڭ";
        switch (this.formData.target) {
          case "film":
            return sprintf(str, "فىلىم")
          case "film_category":
            return sprintf(str, "فىلىم تۈرى")
          case "film_flag":
            return sprintf(str, "فىلىم سەھىپىسى")
          case "film_collection":
            return sprintf(str, "توپلام")
          case "mp":
            return sprintf(str_1, "ئەپچاق 页面路径 سىنى")
          case "external_link":
            return sprintf(str_1, "تور بەت ئادېرسىنى")
          case "page":
            return sprintf(str, "ئىچكى بەتنى")
          case "contract":
            return "ھىچقانداق قىلمىسىڭىزمۇ بولىدۇ."
        }
      },
      shouldChoose(){
        return ['film', 'film_category', 'film_flag', 'film_collection', 'page'].includes(this.formData.target)
      },
      pages(){
        let str = "رەسىمنى چەككەندە %s ئاتلايدۇ. %s ";
        return [
          {
            title: 'باشبەت',
            value: 'home',
            description: sprintf(str, 'باشبەتكە ' , '')
          },
          {
            title: 'ئىزدەش بېتى',
            value: 'search',
            description: sprintf(str, 'ئىزدەش بېتىگە ' , '')
          },
          {
            title: 'بايقاش بېتى',
            value: 'discover',
            description: sprintf(str, 'بايقاش بېتىگە ' , 'تۈرلەر ۋە سەھىپىلەرنى تاللاپ كىنو ئىزدەيدىغان بەت')
          },
          {
            title: 'توپلام بېتى',
            value: 'collection',
            description: sprintf(str, 'توپلام بېتىگە ' , 'بارلىق توپلاملار تىزىلغان توپلام مەركىزىگە كىرىدۇ.')
          },
          {
            title: 'ئەزالار مەركىزى',
            value: 'member',
            description: sprintf(str, 'ئەزالار مەركىزىگە ' , '')
          },
          {
            title: 'ئالى ئەزالىق بىتى',
            value: 'vip',
            description: sprintf(str, 'ئالى ئەزالىق سېتىۋېلىش بېتىگە ' , '')
          }
        ];
      },
      belongs(){
        let { belongs = ['home'] } = this.formData;
        return [
          {
            text: 'ھەممە بەتتە',
            value: 'any',
            disabled: ! belongs.includes('any') && belongs.length
          },
          {
            text: 'باشبەت',
            value: 'home',
            disabled: belongs.includes('any')
          },
          {
            text: 'تەپسىلات بېتى',
            value: 'show',
            disabled: belongs.includes('any')
          },
          {
            text: 'ئەزالار مەركىزى',
            value: 'member',
            disabled: belongs.includes('any')
          }
        ];
      },
      navigationImageDesc(){
        let { view_type } = this.formData;
        if( view_type === 'banner' ){
          return `ئايلانما ئېلان رەسىمىگە ئادەتتە كەڭ رەسىم يوللاش تەۋسىيە قىلىنىدۇ. كەڭلىكنى 750 پېكسېلدا كونترول قىلسىڭىز بىر قەدەر ياخشى .`
        }else if( view_type === 'landscape' ){
          return `لەيلىمە ئېلان رەسىمىگە ئادەتتە تىك ھالەتتىكى رەسىمنى يوللاشنى تەۋسىيە قىلىمىز. ئېگىزلىك كەڭلىكتىن چوڭراق بولغىنى بىر قەدەر ياخشى .`
        }else if( view_type === 'navigation' ){
          return `تىزىملىك سىنبەلگىسى ئادەتتە كەڭلىك ئېگىزلىك 1:1 نىسبەتتىكى رەسىم يوللاشنى تەۋسىيە قىلىمىز. يەنى ئېگىزلىك ۋە كەڭلىك ئوخشاش چوڭلۇقتا بولغان رەسىمنى يوللاڭ، رازمېرى 100 پېكسىل بولسا بىر قەدەر مۇۋاپىق.`;
        }
      },
      targetOptions(){
        return [
          { text: 'فىلىم', value: 'film' },
          { text: 'تۈر', value: 'film_category' },
          { text: 'سەھىپە', value: 'film_flag' },
          { text: 'توپلام', value: 'film_collection' },
          { text: 'ئەپچاق', value: 'mp' },
          { text: 'سىرتقى ئۇلانما', value: 'external_link' },
          { text: 'ئىچكى بەتلەر', value: 'page' },
          { text: 'كېلىشىم', value: 'contract', disabled: ! this.enableList.includes('contract_permission') },
        ];
      }
    },
    created() {
      if (this.action === 'edit'){
        this.getNavigation(this.modelId)
          .then(({ data: navigation })=>{
            this.formData = navigation;
            if ( navigation.navigationable ){
              this.updateTargetTitle(navigation.navigationable.hasOwnProperty('title') ? navigation.navigationable.title : '');
            }
            if( navigation.belongs.includes('show') ){
              this.belongsTitle = navigation.belongsable && navigation.belongsable.title
            }
            if( navigation.target === 'page' ){
              this.pages.map((item)=>{
                if( item.value === navigation.to ){
                  this.updateTargetTitle(item.title);
                }
              })
            }
            if( ['navigation', 'landscape'].includes(navigation.view_type) ){
              this.formData.belongs = ['home'];
            }
          })
      }
    },
    mounted() {
      this.initViewType();
    },
    watch: {
      'formData.view_type': {
        handler(v){
          if ( v !== 'banner' && !! v ){
            this.formData.belongs = ['home'];
          }
        },
        immediate: true
      }
    },
    data(){
      return {
        yesOrNot: [
          { text: 'شۇنداق', value: true },
          { text: 'ياق', value: false },
        ],
        formData: {
          view_type: "",
          title: '',
          color: null,
          background: null,
          target: '',
          to: '',
          target_option: null,
          sort: '',
          icon: null,
          is_show: true,
          belongs: ['home'],
          belongs_option: ''
        },
        viewTypes: [
          { text: 'ئايلانما ئېلان', value: 'banner' },
          { text: 'لەيلىمە ئېلان', value: 'landscape' },
        ],
        targetTitle: null,
        belongsTitle: null,
        filmModalState: false,
        categoryModalState: false,
        flagModalState: false,
        showFlagModalState: false,
        collectionModalState: false,
      }
    },
    methods: {
      onSubmit(){
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          return this.warning({message: 'تولدۇرۇشقا تېگىشلىك ئۇچۇرلارنى تۇلۇق تولدۇرۇڭ'});
        }
        if (this.action === 'create'){
          this.storeNavigation(this.formData);
        }else if ( this.action === 'edit' ){
          this.updateNavigation(this.formData);
        }
      },
      changeTitleColor(e){
        this.formData.color = e;
      },
      changeTarget(e){
        this.formData.to = null;
        this.targetTitle = null;
      },
      initViewType(){
        if ( this.viewType === 'navigation' ){
          this.formData.view_type = 'navigation';
        }else {
          this.formData.view_type = 'banner';
        }
      },

      toggleSomeModal(toggleType = 'filmModalState'){
        this[toggleType] = ! this[toggleType];
      },
      onClickOpenSearchFilmModal(){
        this.toggleSomeModal();
      },

      updateSomeFormData(key = 'to', val = null){
        this.formData[key] = val;
      },
      updateTargetTitle(targetTitle = null){
        this.targetTitle = targetTitle;
      },

      onChangeFilm(e){
        let { film } = e;
        this.updateTargetTitle(film.title);
        this.updateSomeFormData('to', film.id);
        this.$nextTick(()=> {
          setTimeout(()=>{
            this.toggleSomeModal();
          }, 300)
        });
      },
      onChangeFilmCollection(e){
        let { collection } = e;
        this.updateTargetTitle(collection.title);
        this.updateSomeFormData('to', collection.id);
        this.$nextTick(()=> {
          setTimeout(()=>{
            this.toggleSomeModal( 'collectionModalState' );
          }, 300)
        });
      },

      onClickOpenSearchCategoryModal(){
        this.toggleSomeModal('categoryModalState');
      },
      onChangeFilmCategory(e){
        let { category } = e;
        this.updateTargetTitle(category.title);
        this.updateSomeFormData('to', category.id);
        this.$nextTick(()=> {
          setTimeout(()=>{
            this.toggleSomeModal('categoryModalState');
          }, 300)
        });
      },
      onClickOpenSearchFlagModal(){
        this.toggleSomeModal('flagModalState');
      },
      onClickOpenSearchShowFlagModal(){
        this.toggleSomeModal('showFlagModalState');
      },
      onClickOpenSearchCollectionModal(){
        this.toggleSomeModal('collectionModalState');
      },
      onChangeFilmFlag(e){
        let { flag } = e;
        this.updateTargetTitle(flag.title);
        this.updateSomeFormData('to', flag.id);
        this.$nextTick(()=> {
          setTimeout(()=>{
            this.toggleSomeModal('flagModalState');
          }, 300)
        });
      },
      onChangeShowFlag(e){
        let { flag } = e;
        this.belongsTitle = flag.title;
        this.updateSomeFormData('belongs_option', flag.id);
        this.$nextTick(()=> {
          setTimeout(()=>{
            this.toggleSomeModal('showFlagModalState');
          }, 300)
        });
      },
      onChangePage(page){
        this.targetTitle = page.title;
        this.$bvModal.hide('pagesList')
      }
    }
  }
</script>
