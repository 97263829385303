<template>
  <base-modal :id="id" :title="title" :modal-busy="flagBusy" :size="size" v-model="modalState" >
    <pagination :meta="searchFlagListMeta" :search-width="50" place-holder="سەھىپە نامىنى كىرگۈزۈپ ئىزدەڭ" @change-page="onChangePage" @refresh="onRefresh" />
    <b-row>
      <b-col v-for="(flag, index) in searchFlagList" cols="6" :key="flag.id">
        <flag-item :index="index" :selected="selected == flag.id" :item="flag" @changed="onChangeFlagItem" />
      </b-col>
    </b-row>
  </base-modal>
</template>

<script>
  import baseModal from "./base.modal";
  import pagination from "@v@/components/paginate";
  import FlagItem from "@v@/components/items/FlagItem";

  import flagMixin from "@m@/flag.mixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";

  export default {
    name: "searchFilmFlagModal",
    components: { baseModal, pagination, FlagItem },
    props: {
      id: {
        type: String,
        default: () => 'search-film-flag-modal'
      },
      value: {
        type: Boolean,
      },
      title: {
        type: String,
        default: () => 'سەھىپە ئىزدەڭ'
      },
      size: {
        type: String,
        default: ()=> 'lg'
      },
      selected: {
        type: [String, Number]
      }
    },
    computed: {
      modalState: {
        get(){
          return this.value;
        },
        set(v){
          this.$emit('input', v);
        },
      },
      currentSelected(){
        if (this.selectedForComponentEvent){
          return this.selectedForComponentEvent
        }else if (this.selected){
          return this.selected
        }
        return null;
      },
    },
    mixins: [ flagMixin, formBusyMixin ],
    created() {
      this.searchFlag('', 1);
    },
    data(){
      return {
        flagBusy: false,
        page: 1,
        flag_title: '',
        selectedForComponentEvent: null
      };
    },
    methods: {
      onChangePage(e){
        let { title, page } = e;
        this.page = page;
        this.flag_title = title;
        this.searchFlag(title, page);
      },
      onRefresh(title){
        this.searchFlag(title, 1);
      },
      onChangeFlagItem(e){
        let { index, flag } = e;
        this.updateSelectedForComponentEvent(flag.id);
        this.$emit('changed', { flag });
      },
      updateSelectedForComponentEvent(selectedForComponentEvent = null){
        this.selectedForComponentEvent = selectedForComponentEvent;
      }
    }
  }
</script>

<style scoped>

</style>
