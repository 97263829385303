<template>
  <div v-if="item.id" @click="onChange" v-b-tooltip :title="item.title" class="collection-item rounded align-items-center">
    <div class="film-count-wrapper">{{ item.films_count}} دانە فىلىم بار</div>
    <div v-if="selected" class="collection-item-selected-state-wrapper position-absolute d-flex flex-column justify-content-center align-items-center">
      <i class="fa fa-check-circle text-info"></i>
    </div>
    <div class="bottom-title-wrapper position-absolute">
      <div class="bottom-title text-truncate">{{ item.title }}</div>
    </div>
    <b-img class="collection-img align-content-center" fluid rounded :src="item.cover_image_preview" />
  </div>
</template>

<script>
  export default {
    name: "CollectionItem",
    props: {
      item: {
        type: Object,
        default: ()=> {return {}}
      },
      index: {
        type: [Number, String],
      },
      selected: {
        type: Boolean
      }
    },
    computed: {
      backgroundImage(){
        return this.item.cover_image_preview ? this.item.cover_image_preview : '/assets/media/misc/bg-2.jpg';
      },
    },
    methods: {
      onChange(e){
        let { index, item } = this;
        this.$emit('changed', {index, collection: item});
      }
    }
  }
</script>

<style >
  .collection-item {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    box-shadow: 0 0 13px 0 rgba(82,63,105,0.05);

    background-color: #ffffff;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    overflow: hidden;
    height: 150px;
    position: relative;
  }

  .collection-item .collection-img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  }
  .collection-item .film-count-wrapper {
    position: absolute;
    top: 0;
    left: 10px;
    right: 10px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    z-index: 1;
    font-size: 10px;
    color: white;
    text-shadow: 0 0 2px black;
  }

  .collection-item.rounded{
    border-radius: 6px;
  }

  .collection-item-selected-state-wrapper {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  .collection-item-selected-state-wrapper i.fa {
    font-size: 42px;
  }

  .collection-item .bottom-title-wrapper {
    bottom: 0;
    left: 5px;
    right: 5px;
    z-index: 1;
    height: 25px;
    text-shadow: 0 0 2px black;
  }

  .bottom-title {
    position: relative;
    z-index: 2;
    color: white;
    text-shadow: 0 0 2px black;
    text-align: center;
    font-size: 14px;
    line-height: 1.4;
  }

  .no-backcolor {
    background-color: unset !important;
  }
</style>
